<template>
  <div>
    <PageIntroduce
      :title="'业务管理'"
      :desc="'业务管理是指涉及个人信息处理并需要进行个人信息风险评估的业务或应用，可按实际的业务应用特点进行自定义命名。'"
    />
    <b-card>
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <div class="flex-end">
          <el-button
            v-if="importPerm"
            size="small"
            class="mr10"
            type="primary"
            icon="el-icon-upload"
            @click="importDialogShow"
          >
            导入
          </el-button>
          <el-button
            v-if="exportPerm"
            size="small"
            type="primary"
            icon="el-icon-download"
            @click="exportProduct"
          >
            导出
          </el-button>
          <el-button
            v-if="savePerm"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="saveDialogShow(1)"
          >
            新增
          </el-button>
        </div>

      </div>
      <div class="table-wrap">
        <FilterSelectList
          :list-query.sync="listQuery"
          :select-list.sync="selectList"
          @handleFilter="handleFilter"
        />
        <el-table
          v-loading="loading"
          fit
          highlight-current-row
          :data="tableData"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column
            prop="name"
            :show-overflow-tooltip="true"
            label="业务名称"
          />
          <el-table-column
            prop="productType"
            :show-overflow-tooltip="true"
            label="类型"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'productType'"
                :select-list.sync="selectList"
                :label="'类型'"
                :value.sync="listQuery.productType"
                :dropdown-options="typeOptions"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              {{ typeObj[row.productType] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            :show-overflow-tooltip="true"
            label="状态"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'status'"
                :select-list.sync="selectList"
                :label="'状态'"
                :value.sync="listQuery.status"
                :dropdown-options="statusOptions"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              {{ statusObj[row.status] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="productManagerName"
            label="业务负责人"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'productManagerId'"
                :select-list.sync="selectList"
                :label="'业务负责人'"
                :value.sync="listQuery.productManagerId"
                :dropdown-options="productManagers"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="technicalDirectorName"
            label="技术负责人"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'technicalDirectorId'"
                :select-list.sync="selectList"
                :label="'技术负责人'"
                :value.sync="listQuery.technicalDirectorId"
                :dropdown-options="technicalDirectors"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            prop="onlineTime"
            label="上线时间"
          >
            <template slot-scope="scope">
              {{ formatDateStr(scope.row.onlineTime) }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="150px"
          >
            <template slot-scope="{row}">
              <el-link
                v-show="readPerm"
                :underline="false"
                class="mr10"
                type="primary"
                @click="$router.push({path: '/product-detail', query: {id: row.id}})"
              >
                <el-tooltip
                  content="查看"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-document" />
                </el-tooltip>
              </el-link>
              <el-link
                v-show="updatePerm"
                class="mr10"
                :underline="false"
                type="primary"
                @click="saveDialogShow(2,row.id)"
              >
                <el-tooltip
                  content="修改"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>
              <el-link
                v-show="scenarioPerm"
                class="mr10"
                :underline="false"
                type="primary"
                @click="$router.push({path: '/product-scene', query: { id: row.id}})"
              >
                <el-tooltip
                  content="业务场景"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-s-data" />
                </el-tooltip>
              </el-link>
              <el-link
                v-show="delPerm"
                class="mr10"
                type="primary"
                @click="delProduct(row.id)"
              >
                <el-tooltip
                  content="删除"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <PageQuery
          v-show="total>0"
          :total="total"
          :cache-name="'listQuery20'"
          :update-state="'UPDATE_LISTQUERY20'"
          :list-query.sync="listQuery"
          :init-list-query.sync="initListQuery"
          @pagination="getList"
        />
      </div>

    </b-card>
    <ImportProduct
      ref="importProduct"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import ImportProduct from '@/views/enterprise/product/ImportProduct.vue'
import {
  getProductByPage, deleteProduct, GetManagerUsers, GetTechnicalUsers, ExportProduct,
} from '@/api/enterprise/product'
import {
  error, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'

export default {
  components: {
    ImportProduct,
    PageIntroduce,
    FilterSelectList,
    TableHeaderFilterDropdown,
  },
  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      status: '',
      technicalDirectorId: '',
      productManagerId: '',
      productType: '',
    }
    return {
      selectList: [],
      technicalDirectors: [],
      productManagers: [],
      chartLoading: true,
      barSeries: [],
      circleSeries: [],
      importPerm: false,
      savePerm: false,
      updatePerm: false,
      readPerm: false,
      delPerm: false,
      scenarioPerm: false,
      exportPerm: false,
      typeObj: {
        1: '自研',
        2: '外购',
      },
      typeOptions: [{ label: '自研', value: 1 }, { label: '外购', value: 2 }],
      statusOptions: [
        { label: '设计阶段', value: 1 },
        { label: '测试阶段', value: 2 },
        { label: '正式运行', value: 3 },
        { label: '使用阶段', value: 4 },
        { label: '弃用', value: 5 },
      ],
      statusObj: {
        1: '设计阶段',
        2: '测试阶段',
        3: '正式阶段',
        4: '使用阶段',
        5: '弃用',
      },
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.importPerm = verifyBtnPerm(btnPerms, 'product-6')
      this.savePerm = verifyBtnPerm(btnPerms, 'product-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'product-4')
      this.readPerm = verifyBtnPerm(btnPerms, 'product-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'product-5')
      this.scenarioPerm = verifyBtnPerm(btnPerms, 'product-7')
      this.exportPerm = verifyBtnPerm(btnPerms, 'product-8')
      this.scenarioPerm = true
    })
    this.getmanagerUsers()
    this.getTechnicalUsers()
    const cacheQuery = this.$store.state.pageQuery.listQuery20
    this.listQuery = { ...cacheQuery }
    this.getList()
  },
  methods: {
    exportProduct() {
      ExportProduct()
    },
    getmanagerUsers() {
      GetManagerUsers().then(res => {
        if (res.data.code === 0) {
          res.data.data.forEach(item => {
            this.productManagers.push({
              label: item.name,
              value: item.id,
            })
          })
        }
      })
    },
    getTechnicalUsers() {
      GetTechnicalUsers().then(res => {
        if (res.data.code === 0) {
          res.data.data.forEach(item => {
            this.technicalDirectors.push({
              label: item.name,
              value: item.id,
            })
          })
        }
      })
    },
    importDialogShow() {
      this.$refs.importProduct.dialogVisible = true
    },
    delProduct(productId) {
      this.$confirm('是否确认删除产品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        deleteProduct({ id: productId }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.dialogVisible = false
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    saveDialogShow(type, productId) {
      if (type === 2) {
        this.$router.push({
          path: '/product-save',
          query: {
            id: productId,
          },
        })
      } else {
        this.$router.push({
          path: '/product-save',
        })
      }
    },
    detailDialogShow(productId) {
      this.$refs.productDetail.dialogVisible = true
      this.$refs.productDetail.productInfo.id = productId
    },
    getList() {
      this.loading = true
      this.listQuery.status = this.listQuery.status ? this.listQuery.status : 0
      this.listQuery.technicalDirectorId = this.listQuery.technicalDirectorId ? this.listQuery.technicalDirectorId : 0
      this.listQuery.productManagerId = this.listQuery.productManagerId ? this.listQuery.productManagerId : 0
      this.listQuery.productType = this.listQuery.productType ? this.listQuery.productType : 0
      getProductByPage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
